* {
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}
body {
  background-color: rgb(255, 255, 255);
  margin: 0;
  font-size: 16px;
}
.overlay {
  z-index: 50;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; /* background-color: rgba(0, 0, 0, 0.5); */
}
.popup {
  z-index: 100;
  position: fixed;
  top: 200px;
  left: 200px;
  right: 200px;
  background-color: white;
}
.side-home-container {
  position: relative;
}
.jours {
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  font-family: Times, "Times New Roman", serif;
  font-style: italic;
  width: 25vw;
  padding: 15px;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.fold-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}
.firstfold {
  width: 100vw; /* height: 10vh; */
}
.centerfold {
  width: 100vw;
  height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.lastfold {
  width: 100vw; /* height: 10vh; */
}
.header {
  z-index: 200;
}
.footer {
  /* position: fixed; left: 0; bottom: 0; */
  width: 100vw;
  background-color: rgb(255, 255, 255);
  text-align: left;
}
.sidebar-container {
  /* position: absolute; top: 0; left: 0; height: 100vh; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px;
}
.sidebar-homescreenal {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px;
}
.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  text-align: left;
}
.right {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  text-align: left;
}
.left {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
button i {
  margin: 20px 20px 20px 0px;
  text-align: left;
}
button {
  background-color: rgba(255, 255, 255, 0);
  z-index: 2000;
}
.arrow-right {
  text-align: left;
  cursor: pointer;
}
.side {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 2vh 0vh 0vh 2vh;
  font-size: 0.8vh;
}
.fund {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 3vh 0vh 3vh 3vh;
  font-size: 0.8vh;
  text-align: right; /* transform: rotate(-90deg); position: fixed; bottom: 330px; left: -18px; */
}
.fold-container-al {
  position: fixed;
  top: 0;
  left: 14vh;
  height: 100vh;
}

.footer-homescreenal {
  position: fixed;
  left: 125px;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
}
.header-homescreenal {
  position: fixed;
  left: 125px;
  top: 0;
  width: 100%;
}
.left-side-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100vh;
  height: 14vh;
  border-bottom-style: solid;
  border-width: 1px;
  position: fixed;
  top: 100%;
  left: 0;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%; /* -webkit-transform-origin: 50% 50%; */
}
.first-third {
  position: relative; /* transform: rotate(-90deg); */
  width: 29vh;
  height: 2vw;
  margin: 2vh;
}
.second-third {
  /* transform: rotate(-90deg); */
  height: 2vw;
  width: 20vh;
  position: relative;
  margin: 2vh 0vh 2vh 2vh;
}
.section1logo-container {
  width: 16vh;
}
.section1 {
  padding: 2vh;
}
.fund-container {
  width: 10vh;
}
.third-third {
  position: relative; /* bottom: 0; */
  width: 19vh; /* transform: rotate(-90deg); */
}
.rdm-img-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90vw;
  height: 80vh;
}
.random-imgs {
  height: 50vh;
  text-align: center;
  margin-right: 8vw;
  /* -webkit-animation-name: opacity-imgs;
  animation-name: opacity-imgs;
  -webkit-animation-duration: 8s;
  animation-duration: 8s; */
}

/* @-webkit-keyframes opacity-imgs {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacity-imgs {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
/* .logotype {
  width: 45vw;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
@-webkit-keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */
a,
a:visited {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.logotype-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;
  height: 80vh;
  margin: 10vh 10vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.jour1-container {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.lyrics {
  font-size: 3vw;
  margin: 20vh 30vw 20vh 20vw;
}
.x {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 40px;
  z-index: 4000;
} /* .viewport { width: 80vw; height: 80vh; } */
.viewport-container {
  /* position: absolute; top: 0; left: 0; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90vw;
  height: 80vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3000;
}
.viewport-container-al {
  position: absolute;
  top: 10vh;
  left: 7vw;
}
.mouse-div-container {
  position: absolute;
  left: 10vw;
  display: flex;
  width: 80vw;
  height: 80vh;
  justify-content: space-evenly;
}

.mouse-div-container-jour6 {
  position: absolute;
  left: 0;
  display: flex;
  width: 90vw;
  height: 100vh;
  justify-content: space-evenly;
  cursor: pointer;
}

p span {
  cursor: pointer;
}
p span:hover {
  color: white;
}
button:focus {
  outline: 0 !important;
}
.jour6mouseDiv {
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  margin: 0 0.5vw;
  z-index: 3000;
}
.jour6mouseDiv:hover {
  background-color: rgb(255, 255, 255);
  z-index: 3000;
}

#ps {
  display: flex;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.6vw;
  color: grey;
  overflow: hidden;
}
#img {
  /* width: 100vw; */
  position: absolute;
  left: 0px;
  top: 48px;
  overflow: hidden;
}

#text1 {
  padding: 5px;
  height: 100%;
  width: 25%;
}
#text2 {
  padding: 5px;
  height: 100%;
  width: 25%;
}
#text3 {
  padding: 5px;
  height: 100%;
  width: 25%;
}
#text4 {
  padding: 5px;
  height: 100%;
  width: 25%;
}

@media only screen and (max-width: 450px) {
  .jours {
    width: 37vw;
  }
  .fold-container {
    width: 150vw;
  }
  .firstfold {
    width: 150vw;
  }
  .lastfold {
    width: 150vw;
  }
  .footer {
    width: 150vw;
  }
  .centerfold {
    height: 86vh;
    width: 150vw;
  }
  .logotype-container {
    width: 120vw;
  }
  .random-imgs {
    height: 50vw;
    margin-right: 0;
  }
  .viewport-container {
    height: 86vh;
  }
  .left-side-bar {
    top: 55.5%;
  }
  .side-home-container {
    width: 180vw;
    height: 100vh;
  }
  .lyrics {
    font-size: 3.3vh;
    margin: 18vw;
  }
  .h {
    top: 0;
  }
  #img {
    top: 0;
  }
  #ps {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}
@media only screen and (max-width: 330px) {
  .jours {
    width: 50vw;
  }
  .fold-container {
    width: 200vw;
  }
  .firstfold {
    width: 200vw;
  }
  .centerfold {
    height: 80vh;
    width: 200vw;
  }
  .lastfold {
    width: 200vw;
  }
  .viewport-container {
    height: 76vh;
  }
  .logotype-container {
    height: 78vh;
  }
  .footer {
    width: 200vw;
  }
  .left-side-bar {
    top: 46.3%;
  }
  .side-home-container {
    width: 216vw;
    height: 100vh;
  }
}
@media only screen and (max-width: 450px) {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media,
    _:future,
    :root,
    .left-side-bar {
      top: 100%;
    }
    .fund,
    .side {
      font-size: 0.7vh;
    }
  }
}
/* @supports (-webkit-overflow-scrolling: touch) {
  @media only screen and (max-width: 450px) {
    .left-side-bar {
      top: 100% !important;
    }
  }
} */

/* 
@supports (-webkit-touch-callout: none) {
  @media only screen and (max-width: 450px) {
    .left-side-bar {
      top: 100%;
    }
  }
} */
